<template>
    <ion-content class="ion-padding" scroll-y="false">
        <div class="popover-container">
            <div class="popover-title">
                Sort By
            </div>
            <ion-list>
                <ion-radio-group v-model="orderby" @ionChange="selected">
                    <ion-item>
                        <ion-label>Start Date <uil-angle-double-down class="order-icon" size="25px" /></ion-label>
                        <ion-radio slot="start" value="0"></ion-radio>
                    </ion-item>
                    <ion-item>
                        <ion-label>Start Date <uil-angle-double-up class="order-icon" size="25px" /></ion-label>
                        <ion-radio slot="start" value="1"></ion-radio>
                    </ion-item>
                    <!-- <ion-item>
                        <ion-label>Reset</ion-label>
                        <ion-radio slot="start" value="2"></ion-radio>
                    </ion-item> -->
                </ion-radio-group>
            </ion-list>
        </div>
    </ion-content>
</template>

<script>
import { IonContent, IonList, IonItem, IonRadio, IonRadioGroup, popoverController } from '@ionic/vue';
import { defineComponent } from 'vue';

import { UilAngleDoubleDown, UilAngleDoubleUp } from '@iconscout/vue-unicons'

export default defineComponent({
    name: 'OrderbyPopover',
    components: { IonContent, IonList, IonItem, IonRadio, IonRadioGroup, UilAngleDoubleDown, UilAngleDoubleUp },
    props : {
        current_orderby : {
            type: String,
        }
    },
    data () {
        return {
            orderby: "0",
        }
    },
    created () {
        this.orderby = this.current_orderby
    },
    methods : {
        selected(){
            popoverController.dismiss(this.orderby);
        }
    }
});
</script>

<style scoped lang="scss">
    .popover-title {
        color: #373636;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 18px;
    }

    .order-icon {
        margin-bottom: -5px;
        color: #DB9123;
    }
</style>