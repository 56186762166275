<template>
    <ion-content class="ion-padding" scroll-y="false">
        <div class="popover-container">
            <div class="popover-title">
                Filter Jobs
            </div>
            <ion-list>
                <ion-radio-group v-model="filter" @ionChange="selected">
                    <ion-item>
                        <ion-label>Submitted Jobs</ion-label>
                        <ion-radio slot="start" value="0"></ion-radio>
                    </ion-item>
                    <ion-item>
                        <ion-label>Unsubmitted Jobs</ion-label>
                        <ion-radio slot="start" value="1"></ion-radio>
                    </ion-item>
                </ion-radio-group>
            </ion-list>
        </div>
    </ion-content>
</template>

<script>
import { IonContent, IonList, IonItem, IonRadio, IonRadioGroup, popoverController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FilterPopover',
    components: { IonContent, IonList, IonItem, IonRadio, IonRadioGroup },
    props : {
        current_filter : {
            type: String,
        }
    },
    data () {
        return {
            filter: "0",
        }
    },
    created () {
        this.filter = this.current_filter
    },
    methods : {
        selected(){
            popoverController.dismiss(this.filter);
        }
    }
});
</script>

<style scoped lang="scss">

    // ion-label {
    //     padding-left: 0px;
    //     margin-right: 0px;
    // }

    // ion-item {
    //     --padding-start: 0px;
    //     --padding-end: 0px;
    //     --inner-padding-end: 10px;
    //     --inner-padding-start: 10px;
    //     --inner-padding-bottom: 10px;
    //     --inner-padding-top: 10px;
    // }

    .popover-title {
        color: #373636;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 18px;
    }
</style>