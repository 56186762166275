<template>
  <ion-content color="white" fullscreen class="ion-padding" scroll-y="false">
        <div class="close-modal" @click="closeModal">
            <uil-multiply size="20px" />
        </div>  
        <div class="tab" style="padding-top: 5px; padding-left: 5px;">
            <div class="tablinks" @click="selectTab(notes_obj)" :class="{ 'is-active': notes_obj.isActive }">{{ notes_obj.title }}</div>
            <div class="tablinks" @click="selectTab(your_notes_obj)" :class="{ 'is-active': your_notes_obj.isActive }">{{ your_notes_obj.title }}</div>
        </div>

        <div class="tab-container">
            <div  class="tabcontent" :class="{ 'is-active': notes_obj.isActive }">
                {{ notes_obj.content }}
            </div>
        </div>

        <div class="tab-container">
            <div class="tabcontent" :class="{ 'is-active': your_notes_obj.isActive }">
                <!-- {{ your_notes_obj.content }} -->
                <div class="margin-bottom">Write your surveyor notes below:</div>
                <ion-textarea 
                    v-model="your_notes_obj.content" 
                    placeholder="Enter your surveyor notes here..."
                    @ionBlur="saveNotes(your_notes_obj.content)"
                >
                </ion-textarea>
            </div>
        </div>
  </ion-content>
</template>

<script>
import { IonContent, modalController, IonTextarea } from '@ionic/vue';
import { defineComponent } from 'vue';
import { UilMultiply } from '@iconscout/vue-unicons'

export default defineComponent({
    name: 'NotesModal',
    props: [
        'notes',
        'your_notes',
        'job'
    ],
    data() {
        return {
            notes_obj : {
                title : "Notes",
                content : this.notes,
                isActive: true  
            },
            your_notes_obj : {
                title : "Your Notes",
                content : this.your_notes,
                isActive: false 
            },
            job_obj : {}
        }
    },
    mounted() {
        this.job_obj = this.job;
        // this.isActive = this.selected;
    },
    methods : {
        selectTab(selectedTab) {
            // this.tabs.forEach(tab => {
            //     tab.isActive = (tab.title == selectedTab.title);
            // });
            this.notes_obj.isActive = (this.notes_obj.title == selectedTab.title);
            this.your_notes_obj.isActive = (this.your_notes_obj.title == selectedTab.title);
        },
        closeModal(){
            if(this.job_obj.surveyor_notes){
                modalController.dismiss(this.job_obj.surveyor_notes);
            } else {
                modalController.dismiss();
            }
        },
        saveNotes(content){
            this.job_obj.surveyor_notes = content;
        }
    },
    components: { IonContent, UilMultiply, IonTextarea }
});

</script>

<style scoped lang="scss">

    /* Style the tab */
    .tab {
        overflow: hidden;
    }

    /* Style the buttons inside the tab */
    .tab .tablinks {
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        font-size: 14px;
        color: #373636;
    }

    /* Create an active/current tablink class */
    .tablinks.is-active {
        background-color: #fff;
        filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.12));
    }

    .tab-container {
        padding-left: 5px;
        padding-right: 5px;
    }

    .tabcontent {
        display: none;
        padding: 20px;
        border-top: none;
        filter: drop-shadow(0px 4px 16px rgba(0,0,0,0.12));
        background-color: #FFFFFF;
        color: #373636;

        ion-textarea {
            --background: #F5F5F5;
            --padding-start: 5px;
            --padding-end: 5px;
            --padding-top: 5px;
            --padding-bottom: 5px;
            --placeholder-color: #373636;
            --placeholder-opacity: 1;
            --color: #373636;
        }
    }

    /* Style the tab content */
    .tabcontent.is-active {
        display: block;
    }

    .margin-bottom {
        margin-bottom: 10px;
    }

</style>