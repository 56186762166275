<template>
  <ion-page>    
    <ion-content :fullscreen="true">


      <div class="ios hydrated container-page-header">
        <div class="page-title">
          <div></div>
          <h1>Your Jobs</h1>
          <div class="filter-orderby">
            <div class="orange-circle filter" @click="openFilter($event)">
              <uil-filter size="25px"/>
            </div>
            <div class="orange-circle orderby" @click="openOrderby($event)">
              <uil-sort-amount-down size="25px"/>
            </div>
          </div>
        </div>
      </div>

      <template v-if="Object.keys(jobs).length > 0">
      <!-- <template v-if="jobs.length > 0"> -->
        <!-- <ion-card v-for="job in jobs" :key="job.id" class="job-card"> -->
        <ion-card v-for="(job, id) in jobs" :key="job.id" class="job-card">
          <template v-if="job.status != 'Accepted'">
            <div class="img-container">
              <div style="display: none" class="action-btn action" @click="presentActionSheet(id)">
                <Uil-ellipsis-v size="25px" />
              </div>
              <div class="action-btn directions">
                <a :href="job.asset.google" target="_blank">
                <uil-directions size="25px" />
                </a>
              </div>
              <template v-if="job.submitted">
                <div class="job-submitted">Submitted</div>
              </template>
              <img style="height: 170px; width: 100%; object-fit: cover;" :src="job.asset.google_static" />
            </div>
            <ion-progress-bar class="progress" :value="job.progress / 100" color="secondary"></ion-progress-bar>
            <ion-card-content>
                <div class="card-container">
                  <div>
                    <ion-card-title>
                      <h2 class="bold">{{ job.asset.title }}</h2>
                    </ion-card-title>
                    <ion-card-title>
                      <h2>{{ job.asset.address + ", " + job.asset.city + ", " + job.asset.postcode }}</h2>
                    </ion-card-title>
                    <ion-card-subtitle class="job-code">#{{ job.job_code }}</ion-card-subtitle>
                    <ion-card-subtitle class="job-date">Start Date: {{ job.job_date }}</ion-card-subtitle>
                  </div>
                  <div>
                    <div class="action-open">
                      <uil-file-alt size="25px" @click="openNotesModal(job)" />
                    </div>
                    <ion-button color="secondary" fill="solid" class="job-view" @click="openJob(job)">View <template v-if="job.submitted">Submitted </template> ></ion-button>
                  </div>
                </div>

            </ion-card-content>
          </template>
        </ion-card>
      </template>
      <template v-else>
        <div class="no-jobs">There are no jobs assigned to you or no jobs match that filter. Jobs may be hidden using the "Hide from phone" feature in the main dashboard.</div>
      </template>
    
    </ion-content>
  </ion-page>
</template>

<script>
// import { IonContent, IonPage, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/vue';
import { 
  IonContent, IonPage, IonCard, IonCardContent, IonCardSubtitle, 
  IonCardTitle, modalController, IonProgressBar, IonButton,
  popoverController   
} from '@ionic/vue';
import { UilFileAlt, UilFilter, UilEllipsisV, UilDirections, UilSortAmountDown } from '@iconscout/vue-unicons';

import { mapGetters, mapState } from 'vuex';
import NotesModal from '../modals/NotesModal.vue';
import FilterPopover from '../popover/FilterPopover.vue';
import OrderByPopover from '../popover/OrderPopover.vue';


export default {
  name: 'Home',
  components: {
    IonContent, IonPage, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle,
    IonProgressBar, IonButton,
    UilFileAlt, UilEllipsisV, UilDirections, UilFilter, UilSortAmountDown 
  },
  data () {
    return {
      jobs: {},
      original_jobs : {},
      filter : "",
      orderby: ""
    }
  },
  computed: {
    ...mapGetters({
      getJobs: 'brightchecker/jobs',
      // getJob: 'auth/getJob'
    }),
    ...mapState([
      "brightchecker/SET_CURRENT_JOB"
    ])
  },
  methods: {
    openJob(job){
      this.$store.commit("brightchecker/SET_CURRENT_JOB", job);
      this.$router.push({ path: '/job/' + job.id })
    },
    async openNotesModal(job) {
      var asset_notes = job.asset.notes;

      var surveyor_notes = "";
      if(job.surveyor_notes){
        surveyor_notes = job.surveyor_notes;
      }

      if(asset_notes == "" || asset_notes == null){
        asset_notes = "There are no asset notes for this job.";
      }
      
      const modal = await modalController
      .create({
        component: NotesModal,
        componentProps: {
          notes : asset_notes,
          your_notes : surveyor_notes,
          job : job
        },
      })

      modal.onDidDismiss()
        .then((data) => {
          if(data.data != undefined){
            job.surveyor_notes = data.data;
            this.$store.dispatch("brightchecker/saveJob", job);
          }
      });

      return  await modal.present();
    },
    async openFilter(ev){
      const popover = await popoverController
        .create({
          component: FilterPopover,
          cssClass: 'filter-popover',
          event: ev,
          componentProps : {
            current_filter : this.filter
          }
        })
      await popover.present();

      var filter = await popover.onDidDismiss();
      if(filter.data == undefined){
        this.filter = "2"
        this.filterJobs("2")
      } else {
        this.filter = filter.data
        this.filterJobs(filter.data)
      }
    },
    async openOrderby(ev){
      const popover = await popoverController
        .create({
          component: OrderByPopover,
          cssClass: 'orderby-popover',
          event: ev,
          componentProps : {
            current_orderby : this.orderby
          }
        })
      await popover.present();

      var orderby = await popover.onDidDismiss();
      if(orderby.data == undefined){
        this.orderby = "2"
        this.orderByJobs("2")
      } else {
        this.orderby = orderby.data
        this.orderByJobs(orderby.data)
      }

    },
    filterJobs(option){
      var new_job_list = {};

      for(let prop in this.original_jobs){
        let current = this.original_jobs[prop];
        if(option == "0" && current.submitted){
          // submited
          new_job_list[prop] = current
        } else if(option == "1" && !current.submitted){
          // unsubmitted
          new_job_list[prop] = current
        } else if(option == "2"){
          // reset
          new_job_list[prop] = current
        }
      }
      this.jobs = new_job_list
    },
    orderByJobs(option){
      if(option == "0"){
        const sortable = Object.fromEntries(
          Object.entries(this.jobs).sort(function(a,b){
            return a[1].job_date_timestamp - b[1].job_date_timestamp;
          })
        );
        this.jobs = sortable;
        console.log(sortable)
        // sortable.forEach(function(item){
        //   this.jobs[item[0]]=item[1]
        // })

        // submited
        // sortable.sort(function(a,b){
        //   return a.job_date_timestamp - b.job_date_timestamp;
        // });
      } else if(option == "1"){
        // unsubmitted
        // sortable.sort(function(a,b){
        //   return b.job_date_timestamp - a.job_date_timestamp;
        // });

        const sortable = Object.fromEntries(
          Object.entries(this.jobs).sort(function(a,b){
            console.log(b[1].job_date_timestamp, a[1].job_date_timestamp)
            return b[1].job_date_timestamp - a[1].job_date_timestamp;
          })
        );
        console.log(sortable)
        this.jobs = sortable;
      } else if(option == "2"){
        // reset
        this.jobs = this.original_jobs
      }

    },
    pageSetup(){
      this.jobs = this.getJobs;
      console.log(this.jobs)

      this.original_jobs = this.jobs
      this.$store.watch(
        (state, getters) => getters['brightchecker/jobs'],
        () => {
          this.jobs = this.getJobs;
          this.original_jobs = this.jobs
        },
      );
    },

  },
  mounted() {
    // this.pageSetup()
  },
  updated() {
    this.pageSetup()
  }
}
</script>

<style scoped lang="scss">
  h1 {
    text-align: center;
  }

  .job-card {
    border-radius: 0px;
    margin-bottom: 40px;
    .card-container {
      display: flex;

      > div {
        // width: 100%;
        display: flex;
        flex-direction: column;
        &:first-child {
          flex: 2;
        }

        &:last-child {
          justify-content: space-between;
          align-items: flex-end;
          flex: 1;
        }
        
        .action-open {
          border-radius: 50%;
          background-color: #DB9123;
          padding: 5px;
          height: 35px;
          width: 35px;
          color: #172943;
        }

        .job-code, .job-date {
          font-size: 14px;
          font-weight: 800;
          line-height: 24px;
          color: #373636;
          font-family: 'Lato', sans-serif;
        }

        .job-view {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 800;
          line-height: 16px;
        }
      }
    }
    .progress {
      margin-top: -3px;
      height: 10px;
    }
  }

  .img-container {
    position: relative;

    .action-btn {
      position: absolute;
      border-radius: 50%;
      background-color: #DB9123;
      padding: 5px;
      height: 35px;
      width: 35px;
      color: #172943;
      &.action {
        right: 10px;
        top: 10px;
      }
      &.directions {
        left: 10px;
        top: 10px;
      }
    }

    .job-submitted {
      position: absolute;
      right: 0px;
      bottom: 3px;
      background-color: #172943;
      padding: 5px 10px;
      color: #ffffff;
      text-transform: uppercase;
      border-top-left-radius: 10px;
      font-weight: 800;
    }
  }

  .orange-circle {
    border-radius: 50%;
    background-color: #DB9123;
    padding: 5px;
    height: 35px;
    width: 35px;
    color: #172943;
  }

  .page-title {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin: 0px 16px;
    > * {
      flex: 1
    }
    .filter-orderby {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .orange-circle:first-child {
        margin-right: 10px;
      }
    }
  }

  .no-jobs {
    text-align: center;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }

  h2.bold {
    font-weight: 700;
  }

</style>